:root {
  @media (prefers-color-scheme: light) {
    --color-background: #fff;
    --color-text: #333;
  }
  @media (prefers-color-scheme: dark) {
    --color-background: #333;
    --color-text: #fff;
  }
}

$COLOR_BACKGROUND: var(--color-background);
$COLOR_TEXT: var(--color-text);

//メディアクエリ設定
$mq-breakpoints: (
  xl: 1440px,
  lg: 1280px,
  md: 1024px,
  tb: 768px,
  sm: 576px,
  xs: 414px,
) !default;

@mixin mq($breakpoint: md, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);

  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint - 0.0625;
  }

  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

body {
  background-color: $COLOR_BACKGROUND;
}

// Header
header {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .header-logo {
    position: absolute;
    text-decoration: none;

    .header-title {
      margin: 0 auto;
      color: #4a77d2;
      line-height: 1;
      font-family: cursive;
      font-weight: 400;
      @media (prefers-color-scheme: dark) {
        color: $COLOR_TEXT;
      }
    }
  }
}

// Footer
footer {
  padding: 10px 0 30px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $COLOR_TEXT;

  .link-container {
    margin: 40px 0 0 0;
    display: flex;
    align-items: center;

    @include mq(tb, max) {
      display: block;
    }
  }

  .copyright {
    font-family: "Noto Serif", serif;
    font-size: 14px;
    letter-spacing: 0.08em;
    margin: 10px 0 30px 0;
  }
}

// Main
.main-container {
  text-align: center;
  height: 68vh;
  min-height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: change-gradient 13s ease infinite;

  @keyframes change-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .main-title {
    margin: 8px 0;
    padding: 0 30px;
    font-family: "Noto Serif", serif;
    font-weight: 400;
    font-size: 38px;
    letter-spacing: 0.08em;
    color: #fff;

    @media (prefers-color-scheme: dark) {
      color: #333;
    }
  }

  .main-text {
    margin: 0 auto;
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 24px;
    color: #fff;
    max-width: 500px;

    @media (prefers-color-scheme: dark) {
      color: #333;
    }
  }

  // テキストアニメーション
  .up-text-animation {
    display: flex;
    overflow: hidden;

    span {
      transform: translateY(110%);
      display: block;
      animation: 3.5s up-text-animation linear forwards;

      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.05s;
      }
      &:nth-child(3) {
        animation-delay: 0.1s;
      }
      &:nth-child(4) {
        animation-delay: 0.15s;
      }
      &:nth-child(5) {
        animation-delay: 0.2s;
      }
      &:nth-child(6) {
        animation-delay: 0.25s;
      }
      &:nth-child(7) {
        animation-delay: 0.3s;
      }
      &:nth-child(8) {
        animation-delay: 0.35s;
      }
      &:nth-child(9) {
        animation-delay: 0.4s;
      }
      &:nth-child(10) {
        animation-delay: 0.45s;
      }
      &:nth-child(11) {
        animation-delay: 0.5s;
      }
      &:nth-child(12) {
        animation-delay: 0.55s;
      }
      &:nth-child(13) {
        animation-delay: 0.6s;
      }
      &:nth-child(14) {
        animation-delay: 0.65s;
      }
      &:nth-child(15) {
        animation-delay: 0.7s;
      }
      &:nth-child(16) {
        animation-delay: 0.75s;
      }
    }
  }

  @keyframes up-text-animation {
    0% {
      transform: translateY(110%);
    }
    13% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}

//Professional Experience
.experience-container {
  color: $COLOR_TEXT;

  .experience-wrapper {
    margin: 80px 30px;

    .experience-title {
      font-size: 32px;
      letter-spacing: 0.08em;
      font-family: "Noto Serif", serif;
      font-style: normal;
      font-weight: bold;

      @include mq(md) {
        width: 100%;
        text-align: center;
      }
    }

    .experience-item-container {
      font-family: "Noto Serif", serif;
      padding: 18px 0;
      margin: 30px 0;
      position: relative;
      border-bottom: #4a77d2 1px solid;
      letter-spacing: 0.06em;

      @include mq(md) {
        text-align: center;
        margin: 24px 200px 40px;
        padding: 18px 0;
      }

      .experience-item-title {
        font-family: "Noto Serif", serif;
        font-size: 23px;
        margin: 0 auto;
      }

      .experience-item-term {
        font-size: 17px;
        line-height: 24px;
        margin-top: 0;
      }

      .experience-item-languages-text {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
      }

      .experience-item-languages {
        font-size: 16px;
        line-height: 24px;
        margin-top: 4px;
        margin-bottom: 20px;
      }
    }
  }
}

// Tweet
.tweet-container {
  background-color: #00acee;
  text-align: center;
  min-height: 45vh;
  padding: 40px 30px 80px;

  .tweet-title {
    font-size: 32px;
    letter-spacing: 0.08em;
    color: #fff;
    font-family: "Noto Serif", serif;
    font-style: normal;
    font-weight: bold;
    z-index: 1;

    @media (prefers-color-scheme: dark) {
      color: #333;
    }

    @include mq(md) {
      width: 100%;
      text-align: center;
    }
  }

  div {
    margin: 0 auto;
  }
}

// NotFound
.error-container {
  font-family: "Noto Serif", serif;
  margin: 0 15px;

  .error-code {
    font-size: 80px;
    font-weight: bold;
    color: #fff;

    @include mq(md) {
      font-size: 120px;
    }
  }

  .error-text {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;

    @include mq(md) {
      font-size: 24px;
    }
  }
}
